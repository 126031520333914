var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-0",style:(("background-size: cover; height:" + (_vm.$vuetify.breakpoint.xl
  ? '1000px'
  : _vm.$vuetify.breakpoint.lg
    ? '600px'
    : _vm.$vuetify.breakpoint.md
      ? '400px'
      : _vm.$vuetify.breakpoint.sm
        ? '350px'
        : '170px'))),attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xs),expression:"$vuetify.breakpoint.xs"}],staticClass:"mt-16 mt-sm-0"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl),expression:"$vuetify.breakpoint.xl"}],staticClass:"mt-xl-16"})],1),_c('v-container',{staticClass:"my-16",attrs:{"fluid":""}},[_c('div',{staticClass:"mt-2 mt-sm-5 px-3 px-sm-0"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }