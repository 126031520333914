import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import i18n from './i18n'
import { createProvider } from './vue-apollo'
import moment from "vue-moment";
Vue.config.productionTip = false
Vue.use(moment);
Vue.use(Croppa);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
