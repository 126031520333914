<template>
  <v-container fluid class="ma-0 pa-0">

    <!-- background-image: url(${require('@/assets/back.jpeg')}); -->
    <v-container fluid class="ma-0 pa-0" :style="`background-size: cover; height:${$vuetify.breakpoint.xl
    ? '1000px'
    : $vuetify.breakpoint.lg
      ? '600px'
      : $vuetify.breakpoint.md
        ? '400px'
        : $vuetify.breakpoint.sm
          ? '350px'
          : '170px'
    }`">
      <v-row class="mt-16 mt-sm-0" v-show="$vuetify.breakpoint.xs"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <v-row class="mt-xl-16" v-show="$vuetify.breakpoint.xl"></v-row>
      <!-- <v-row class="mt-n16 mt-sm-16">
        <v-col offset="1" offset-sm="1" offset-md="1" offset-lg="1" lg="5" md="5" sm="5" cols="5" class="mt-16 pe-sm-0">
          <v-row class="text-h6 text-sm-h5 text-md-h4 text-lg-h3 text-xl-h2 mt-n5 mt-sm-0 mt-md-16 font-weight-bold">
            <span class="primary--text me-2">Your</span> meal plan,<br />
            <span>delivered fresh every day!</span>
          </v-row>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container class="my-16" fluid>
    
      <div class="mt-2 mt-sm-5 px-3 px-sm-0">
 
      </div>
      <!-- <v-row class="mt-5 mt-sm-10 px-lg-16" justify="center">
        <v-col cols="12" md="4" v-for="item in items" :key="item.title" class="">
          <v-card class="pa-8 mx-auto " :height="
            $vuetify.breakpoint.xl
              ? 300
              : $vuetify.breakpoint.lg
                ? 260
                : $vuetify.breakpoint.md
                  ? 300
                  : $vuetify.breakpoint.sm
                    ? 260
                    : ''
          " :width="$vuetify.breakpoint.lgAndDown ? 500 : ''">
            <v-row justify="center">
              <v-img :src="item.image" :height="
                $vuetify.breakpoint.xl
                  ? '60'
                  : $vuetify.breakpoint.smAndUp
                    ? '50'
                    : '40'
              " contain />
            </v-row>
            <v-row class="text-body-1 text-sm-h6 text-xl-h5 mt-2 font-weight-bold primary--text" justify="center">
              {{ item.title }}
            </v-row>
            <v-row class="text-body-2 text-sm-body-1 text-xl-h5 text-center md:text-justify mt-5 ">
              {{ item.description }}
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Quick Delivery",
          image: require("@/assets/service1.svg"),
          description:
            "Convenience is king. Multiple daily delivery options to suit your needs.",
        },
        {
          title: "Always On-Time",
          image: require("@/assets/service2.svg"),
          description:
            "Right food at the right time is the key to a healthy body. Never stay hungry with our precise delivery channels.",
        },
        {
          title: "Delicious Meals",
          image: require("@/assets/service3.svg"),
          description:
            "Meals that are freshly prepared every day from premium ingredients by our world class chefs. Introduce variety and freshness to your meal plan with us.",
        },
      ],
    };
  },
};
</script>