<template>
  <v-app id="app">
    <v-main>
      <Header />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/core/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>
<style>
html,
body {
  overflow-x: hidden;
}
</style>
