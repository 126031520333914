<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :height="$vuetify.breakpoint.smAndUp ? '40px' : ''"
          :small="$vuetify.breakpoint.xs"
          outlined
          color="primary"
          class="ms-n16 ms-sm-0"
        >
          SIGN UP
        </v-btn>
      </template>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="0">
          <v-card class="pa-3" rounded="xl">
            <!-- <span class="primary--text" v-show="isDisable">Customer Details</span> -->
            <v-row justify="end" class="pe-5">
              <v-icon @click="close" color="primary">mdi-close</v-icon>
            </v-row>
            <v-row
              class="text-h6 text-sm-h5 font-weight-bold mb-4"
              justify="center"
              >Sign Up</v-row
            >

            <v-divider class="mb-6"></v-divider>
            <v-card-text>
              <v-container fluid>
                <v-form
                  @submit="customerSignUp()"
                  class="mt-n2 mt-md-n8 pa-md-8 ms-sm-16 ms-md-5"
                  v-model="valid"
                  ref="form"
                >
                  <v-layout wrap>
                    <v-flex xs12 sm10 md11>
                      <v-text-field
                        label="First Name(English)"
                        filled
                        dense
                        :rules="[requiredValidator('first name ')]"
                        color="red"
                        v-model="firstnameEn"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap class="mt-n3">
                    <v-flex xs12 sm10 md11>
                      <v-text-field
                        label="Last Name (English)"
                        filled
                        dense
                        :rules="[requiredValidator('last name')]"
                        v-model="lastnameEn"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm10 md11 class="mt-n3">
                      <v-text-field
                        label="First Name(Arabic)"
                        filled
                        dense
                        :rules="[requiredValidator('first name(arabic)')]"
                        v-model="firstnameAr"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm10 md11 class="mt-n3">
                      <v-text-field
                        label="Last Name (Arabic)"
                        filled
                        dense
                        :rules="[requiredValidator('last name(arabic)')]"
                        v-model="lastnameAr"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap>
                    <v-flex xs12 sm10 md11 class="mt-n3">
                      <v-text-field
                        class="inputPhone"
                        label="Contact Number"
                        filled
                        dense
                        :rules="[
                          requiredValidator('phone'),
                          phoneLengthValidator('phone', 8),
                          this.phoneExist != true || 'Phone already exists.',
                        ]"
                        @click="phoneExist = false"
                        type="number"
                        v-model="phone"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm10 md11 class="mt-n3">
                      <v-text-field
                        label="Email"
                        filled
                        dense
                        v-model="email"
                        :rules="[
                          requiredValidator('email'),
                          emailValidator(),
                          this.doesUserExist != true || 'Email already exists.',
                        ]"
                        @click="doesUserExist = false"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout wrap>
                    <v-flex xs12 sm10 md11 class="mt-n3">
                      <v-text-field
                        label="Password"
                        filled
                        :rules="[
                          requiredValidator('password'),
                          minLengthValidator('password', 8),
                        ]"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        @click:append="show1 = !show1"
                        dense
                        v-model="password"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <!-- {{ new Date() | moment("YYYY-MM-DD") }} -->

                  <v-layout justify-center class="mb-md-n10 pe-sm-16 pe-md-8">
                    <v-btn
                      color="primary"
                      :width="$vuetify.breakpoint.smAndUp ? 250 : 150"
                      depressed
                      :disabled="!valid"
                      @click="customerSignUp()"
                      :loading="btnloading"
                      :small="$vuetify.breakpoint.xs"
                      >CONTINUE</v-btn
                    >
                  </v-layout>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="1">
          <v-card class="pa-3">
            <v-card-title>
              <span class="text-h6 primary--text">OTP Verification</span>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeOtp"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              class="pa-sm-8 mt-n6"
              @submit="verifyCustomer()"
              v-model="isValid"
              ref="form"
            >
              <v-card-text>
                <v-container>
                  <v-layout wrap>
                    <v-flex xs8 md4 class="mt-5 mt-md-0">
                      <v-text-field
                        label="OTP"
                        v-model="otp"
                        outlined
                        dense
                        :rules="[
                          requiredValidator('otp'),
                          this.invalidOTP != true || 'Invalid OTP.',
                        ]"
                        @click="invalidOTP = false"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout justify-center>
                    <v-flex xs8 md2 class="mt-8"> </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions class="mb-n4 mt-n16">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  width="120"
                  :disabled="!isValid"
                  @click="verifyCustomer"
                  >Confirm</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-dialog>
  </div>
</template>

<script>
import {
  requiredValidator,
  emailValidator,
  minLengthValidator,
  phoneLengthValidator,
} from "../methods/validations";
import { onLogin } from "../vue-apollo.js";
export default {
  components: {},
  data() {
    return {
      minLengthValidator: minLengthValidator,
      emailValidator: emailValidator,
      phoneLengthValidator: phoneLengthValidator,
      doesUserExist: false,
      phoneExist: false,
      requiredValidator: requiredValidator,
      invalidOTP: false,
      otp: "",
      tab: 0,
      otpValue: "",
      isValid: true,
      dialog: false,
      show1: false,
      menu2: false,
      firstnameEn: "",
      lastnameEn: "",
      firstnameAr: "",
      lastnameAr: "",
      phone: "",
      email: "",
      password: "",
      valid: true,
      errors: [],
      show_dialog_box: false,
      btnloading: false,
      isForgetPassword: false,
    };
  },
  created() {},
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    closeOtp() {
      this.show_dialog_box = false;
    },
    customerSignUp() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/customerSignUp.graphql"),
          variables: {
            firstnameEn: this.firstnameEn,
            lastnameEn: this.lastnameEn,
            firstnameAr: this.firstnameAr,
            lastnameAr: this.lastnameAr,
            email: this.email,
            phone: this.phone,
            password: this.password,
          },
        })
        .then((data) => {
          onLogin(
            this.$apollo.provider.defaultClient,
            data.data.customerSignUp.token
          );
          this.tab = 1;
          this.$apollo
            .mutate({
              mutation: require("../api/sendOTP.graphql"),
              variables: {
                isForgetPassword: this.isForgetPassword,
                phone: this.phone,
                email: this.email,
              },
            })
            .then((data) => {
              this.otpValue = data.data.sendOTP.customerId;
            });
          this.$refs.form.reset();
          this.btnloading = false;
        })

        .catch((error) => {
          this.phoneExist = false;
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: EMAIL_ALREADY_EXIST") {
              this.doesUserExist = true;
            }
            if (error.message === "GraphQL error: ALREADY_EXIST") {
              this.phoneExist = true;
            }
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    verifyCustomer() {
      this.$apollo
        .mutate({
          mutation: require("../api/verifyCustomer.graphql"),
          variables: {
            otp: this.otp,
          },
        })
        .then(() => {
          this.show_dialog_box = false;
          this.tab = 0;
          this.$router.replace({ path: "thank-you" });
        })
        .catch((error) => {
          this.invalidOTP = true;
          this.btnloading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style>
.inputPhone input[type="number"] {
  -moz-appearance: textfield;
}
.inputPhone input::-webkit-outer-spin-button,
.inputPhone input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  width: 0% !important;
}
.v-dialog {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>