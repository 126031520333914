<template>
  <v-app-bar class="ma-0 pa-0 white" elevate-on-scroll app>
    <v-img
      :height="$vuetify.breakpoint.smAndUp ? '36' : '25'"
      src="../../assets/logo.svg"
      contain
      position="left"
    />
    <v-spacer></v-spacer>
    <app-userSignup-form class="ms-n16 ms-sm-0" v-if="false" />
  </v-app-bar>
</template>
<script>
import UserSignup from "../../components/UserSignup.vue";
export default {
  components: {
    "app-userSignup-form": UserSignup,
  },
};
</script>