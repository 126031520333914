import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#004438',
                secondary: '#412b74',
                violet:'#7c62dd'
            },
            light: {
                primary: '#004438',
                secondary: '#412b74',
                violet:'#7c62dd'
            },
        },
    },
})
